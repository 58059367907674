import './Styles/getinvolved.css';
import Nav from './Components/Nav';
import Footer from './Components/Footer';
import { RiTeamFill } from "react-icons/ri";
import { BsFillSuitcaseLgFill } from "react-icons/bs";


export default function GetInvolved() {

    return (
        <>
            <Nav></Nav>
            <section className='page-content'>
                <div className='get-involved-wrapper'>
                    <a href='/get-involved/volunteer'>    
                        <div className='volunteer-wrapper'>
                            <h1 className='primary-title'>VOLUNTEER</h1>
                            <div className='learn-more-wrapper'>
                                <seperator></seperator>
                                <p>Explore Opportunities</p>
                                <seperator></seperator>
                            </div>
                            <RiTeamFill />
                        </div>
                    </a>
                    <a href='/get-involved/jobs'>    
                        <div className='careers-wrapper'>
                            <h1 className='primary-title'>CAREERS</h1>
                            <div className='learn-more-wrapper'>
                                <seperator></seperator>
                                <p>Explore Opportunities</p>
                                <seperator></seperator>
                            </div>
                            <BsFillSuitcaseLgFill />
                        </div>
                    </a>
                </div>
            </section>
            <Footer></Footer>
        </>
    );
};