import '../Styles/nav.css';
import React, { useState } from 'react';
import Logo from '../Media/Logos/CSFP-Logo-white.png'
import { RxHamburgerMenu } from "react-icons/rx";

export default function Nav() {
    const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);

    const handleHamburgerClick = () => {
      setMobileMenuOpen(!isMobileMenuOpen);
    };

    return(
        <div className="nav-wrapper">
            <div className="nav">
                <a href="/"><img src={Logo} className="nav-logo"/></a>
                <div id="nav-hambuger" onClick={handleHamburgerClick}>
                    <RxHamburgerMenu />
                </div>
                <menu className="desktop-menu">
                    <a href="/about">About</a>
                    <a href="/events">Events</a>
                    <a href="/community-partners">Community Partners</a>
                    <a href="/get-involved">Get Involved</a>
                    <a href="/contact">Contact</a>
                </menu>
            </div>
            <div id="mobile-menu-wrapper" className={`mobile-toggle ${isMobileMenuOpen ? 'open' : ''}`}>
                <menu className="mobile-menu">
                    <a href="/about">About</a>
                    <a href="/events">Events</a>
                    <a href="/community-partners">Community Partners</a>
                    <a href="/get-involved">Get Involved</a>
                    <a href="/contact">Contact</a>
                </menu>
            </div>
        </div>
    )
}

