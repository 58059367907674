import './Styles/about.css';
import Nav from './Components/Nav';
import Footer from './Components/Footer';
import { ImCheckmark } from "react-icons/im";
import { FaPuzzlePiece } from "react-icons/fa6";
import { FaLightbulb } from "react-icons/fa";
import { MdDiversity3 } from "react-icons/md";
import { FaShield } from "react-icons/fa6";
import { FaFireFlameCurved } from "react-icons/fa6";

export default function About() {
    return (
        <>
        <Nav />        
        <div className='about-page-banner'></div>
        <section className='page-content'>
            <h1 className='title'><span className='highlight'>Purpose</span> in Cultural Innovation</h1>
            <div className='about-description-wrapper'>
                <p className='about-description'>To develop, produce, support, promote and present ethno culturally relevant heritage-based works and experiences which elevate public appreciation, understanding and participation in Canada’s rich multicultural traditions. </p>
            </div>
            <h1 className='title'><span className='highlight'>Mission</span> for Impact</h1>
            <div className='about-description-wrapper'>
                <p className='about-description'>To showcase Canada’s rich diversity and the contributions of various multicultural groups and communities to Canadian society with a focus on celebrating the best of the Latino culture in Canada enjoyed by Canada’s 1.8 million Spanish speakers and all Canadians with an appetite and passion for Latino-themed events, festivals, programs and lifestyle.</p>
            </div>
            <h1 className='title'><span className='highlight'>Vision </span>for a Bright Future</h1>
            <div className='about-description-wrapper'>
                <p className='about-description'>To become Canada’s internationally renowned ambassador of Latino lifestyle, music, dance, arts and cuisine.</p>
            </div>
            <h1 className='title'>Core <span className='highlight'>Values</span> in Action</h1>
            <div className='value-card-wrapper'>
                <ValueCard 
                    icon={ImCheckmark}
                    title={"Accountability"}
                    description={"Consistently delivering on our commitments with utmost respect for all stakeholders."}/>
                <ValueCard 
                    icon={FaPuzzlePiece}
                    title={"Collaboration"}
                    description={"Encouraging teamwork and partnerships with staff, volunteers, the board, and stakeholders."}/>
                <ValueCard 
                    icon={FaLightbulb}
                    title={"Creativity"}
                    description={"Addressing challenges while actively identifying and leveraging opportunities."}/>
                <ValueCard 
                    icon={MdDiversity3}
                    title={"Inclusivity"}
                    description={"Engaging and collaborating with diverse communities."}/>
                <ValueCard 
                    icon={FaShield}
                    title={"Integrity"}
                    description={"Dedication to the accurate, fair, and responsible stewardship of the Canadian Salsa Festivals Project."}/>
                <ValueCard 
                    icon={FaFireFlameCurved}
                    title="Passion"
                    description={"Realizing our mission and vision through a dedicated and professional commitment to our goals and objectives."}/>
            </div>
            <h1 className='title'><span className='highlight'>History</span></h1>
            
            <ul className='timeline'>
                <li class="timeline-item">
                    <h3 class="sub-title timeline-date">July 2005</h3>
                    <div className='timeline-info-wrapper'>
                        <div class="timeline-title">Fiesta Debut: TD Salsa on St. Clair Kickoff</div>
                        <div class="timeline-description">{"The inaugural TD Salsa on St. Clair Street Festival is launched by the Salsa Festival Group, a division of Telelatino Network Inc. (now TLN Media Group). Inspired by Miami’s \"Calle Ocho\" festival, the first event draws an estimated 100,000 attendees, establishing the foundation for future editions."}</div>
                    </div>
                </li>
                <li class="timeline-item">
                    <h3 class="sub-title timeline-date">Evolution under TLN</h3>
                    <div className='timeline-info-wrapper'>
                        <div class="timeline-title">TMG's Salsa Fest: Local Fiesta to National Spectacle</div>
                        <div class="timeline-description">{"The Festival, managed by TLN Media Group, transforms from a local community fiesta into the nationally-recognized TD Salsa in Toronto Festival. Over the years, it grows into a multi-event, multi-venue celebration, attracting over 3 million cumulative visitors to the city."}</div>
                    </div>
                </li>
                <li class="timeline-item">
                    <h3 class="sub-title timeline-date">Summer 2013</h3>
                    <div className='timeline-info-wrapper'>
                        <div class="timeline-title">TD Salsa on St. Clair: Cultural Showcase for Toronto 2015 Games</div>
                        <div class="timeline-description">{"The TD Salsa on St. Clair Festival is chosen by the City of Toronto Economic Development & Culture Department for the Toronto 2015 Pan/Parapan American Games Host City Showcase Funding Program. This recognition acknowledges the cultural synergies and links between the Games and the Festival, given the predominantly Latin American national participation in the Games."}</div>
                    </div>
                </li>
                <li class="timeline-item">
                    <h3 class="sub-title timeline-date">October 30, 2015</h3>
                    <div className='timeline-info-wrapper'>
                        <div class="timeline-title">CSFP: Guiding Future TD Salsa and Cultural Festivals</div>
                        <div class="timeline-description">{"In line with its growth, a not-for-profit Corporation named Canadian Salsa Festivals Project (CSFP) is established under the Canada Not-for-profit Corporations Act. CSFP assumes the responsibility to oversee, undertake, and organize all future productions of the TD Salsa in Toronto Festival and other cultural festivals."}</div>
                    </div>
                </li>
            </ul>
        </section>
        <Footer />
        </>
    );
};

function ValueCard ({icon: IconComponent, title, description}) {
    return (
        <>
            <div className='value-card'>
                <IconComponent className='value-icon'/>
                    <h1 className='value-title'>{title}</h1>
                    <p className='value-description'>{description}</p>
            </div>
        </>
    )
}