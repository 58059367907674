import Nav from './Components/Nav';
import Footer from './Components/Footer';
import Slider from './Components/Slider';
import EventCard from './Components/EventCard';
import EventSliderJson from './Media/Slides/Events_Slider/filenames.json';
import slide1 from './Media/Slides/Events_Slider/EventSlider_0000_bolivian_2560x742.webp';
import slide2 from './Media/Slides/Events_Slider/EventSlider_0001_salsa_on_stage-2560x742.webp';
import slide3 from './Media/Slides/Events_Slider/EventSlider_0002_2560x742.webp';
import slide4 from './Media/Slides/Events_Slider/EventSlider_0003_MissionandVision_2560x742.webp';

  const slides = [
      {
          "index": 0,
          "filename":slide1,
          "alt":""
        },
        {
          "index": 1,
          "filename":slide2,
          "alt":""
        },
        {
          "index": 2,
          "filename":slide3,
          "alt":""
        },
        {
          "index": 3,
          "filename":slide4,
          "alt":""
        }
      ];

  const videoId = [
    {
      "title": '2023 TD Salsa in Toronto | Canada\'s Biggest Latin Fiesta | TLN Connects',
      "id":'B3euu7yMqHE?si=0iNrpnUtdclNp7y2',
    },
    {
      "title": 'TLN Television connects with Salsa goers at the TD Salsa on St. Clair Festival | 2019',
      "id":'VHP_8svqRDM?si=7xnGapiTmqRLlTdO',
    },
    {
      "title": 'La Fiesta Parade - TD Salsa In Toronto Festival',
      "id":'coKHcpeoUw0?si=jT5CSMtie7rYJr6L',
    },
    {
      "title": 'The Virtual Exhibition - Havana 500 Collection with Angel Torres | Part 1',
      "id":'sFJbUjr2YVQ?si=7-5fSWoVrudsYA5J',
    }
  ];

export default function Events() {
    return (
        <>
            <Nav />
            <Slider sliderJson={slides} />
            <section className='page-content'>
              <EventCard 
                eventName="TD Salsa In Toronto" 
                eventInfo="The largest Latino-themed cultural celebration in Canada, the award-winning TD Salsa in Toronto Festival presents some of the most unique cultural experiences featuring the annual TD Salsa on St. Clair Street Festival. In addition, a jam-packed calendar of events includes the best of Latino music, dance, visual arts, and culture with salsa dance parties, art exhibitions, free dance lessons at various schools across the city." 
                link="https://www.salsaintoronto.com/"
                videoId={videoId[0].id} />
              <EventCard 
                eventName="TD Salsa On St Clair Street Festival" 
                eventInfo="As part of Canada’s #1 annual Latino-themed cultural celebration, TD Salsa on St. Clair is the main event in the TD Salsa in Toronto Festival lineup and takes over midtown Toronto every July. TD Salsa on St. Clair is the only festival where people do more than just eat and watch entertainment – they take part in non-stop dancing in the streets!  Canada’s hottest salsa party features an international caliber of Latin music, authentic cuisine and a fiesta for lovers of the Latino culture." 
                link="https://salsaintoronto.com/td-salsa-on-st-clair/" 
                videoId={videoId[1].id}/>
              <EventCard 
                eventName="TLN Fiesta Parade" 
                eventInfo="TLN Fiesta Parade celebrates the rich Latin lifestyle and culture that make up a part of our global Toronto city. Every year, tens of thousands of people gather in the streets of Toronto to celebrate the best of all things latino!" 
                link="https://salsaintoronto.com/tln-fiesta-parade/" 
                videoId={videoId[2].id}/>
              <EventCard 
                eventName="Latin Art Exhibitions" 
                eventInfo="Every year, TD Salsa in Toronto presents a Latin Art Exhibition highlighting the most inspiring pieces from Latino and Hispanic artists celebrating Canada’s rich multiculturalism and Toronto’s love for Latin culture." 
                link="https://salsaintoronto.com/artexhibitions/" 
                videoId={videoId[3].id}/>
              <EventCard 
                eventName="Salsa For Seniors" 
                eventInfo="In an effort to fully include seniors in our community, since 2021 we’ve introduced a new program called, ‘Salsa for Seniors’ as part of the annual TD Salsa in Toronto Festival activities!" 
                link="/salsa-for-seniors"
                videoId='' />
            </section>
            <Footer />
        </>

    );
};