import './Styles/contact.css';
import React, {useState, useEffect} from 'react';
import Nav from './Components/Nav';
import Footer from './Components/Footer';
import { IoCheckmarkCircleOutline } from "react-icons/io5";
import { FaPhoneAlt } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa6";
import { FaFacebookSquare } from "react-icons/fa";
import { FaSquareXTwitter } from "react-icons/fa6";
import { FaSquareYoutube } from "react-icons/fa6";
import { FaInstagramSquare } from "react-icons/fa";

export default function Contact() {
    const [result, setResult] = useState("");
    
    const onSubmit = async (event) => {
        event.preventDefault();
        setResult("Sending....");
        const formData = new FormData(event.target);
    // eddb73bd-812f-4ed8-8e6f-9edf4293fc0e
        formData.append("access_key", "eddb73bd-812f-4ed8-8e6f-9edf4293fc0e");
    
        const response = await fetch("https://api.web3forms.com/submit", {
            method: "POST",
            body: formData
        });
    
        const data = await response.json();
    
        if (data.success) {
            setResult("Form Submitted Successfully");
            event.target.reset();
            document.getElementById("form-section").style.display = 'none';
            document.getElementById("form-submmission-message").style.display = 'flex';
        } else {
            console.log("Error", data);
            setResult(data.message);
        }
    };

    return (
        <>
            <Nav />
                <section className='page-content' id="contact-us-page-content">
                    <div className='contact-us-wrapper'>
                        <div className='contact-form-wrapper'>
                            <div id="form-section">
                                <h1>Get In Touch!</h1>
                                <form id="contact-us-form" onSubmit={onSubmit}>
                                {/* <!-- Replace with your Access Key --> */}
                                    <input type="hidden" name="access_key" value="YOUR_ACCESS_KEY_HERE"></input>
                                    <input className="form-single-line-input" type="text" name="name" placeholder="Full Name *" required></input>
                                    <input className="form-single-line-input"  type="email" name="email" placeholder="Email *" required></input>
                                    <input className="form-single-line-input"  type="tel" name="phone" placeholder="Phone Number" required></input>
                                    <input className="form-single-line-input"  type="text" name="subject" placeholder="Subject" required></input>
                                    <textarea className="form-box-line-input" rows="3" cols="25" placeholder="How can we help you?" required></textarea>
                                    <input className="form-submit-btn" type="submit" placeholder='Submit'></input>
                                </form>
                            </div>
                            <div id="form-submmission-message">
                                <IoCheckmarkCircleOutline />
                                <h1>Thank You!</h1>
                                <p>Your submission has been successfully recieved.</p>
                            </div>
                        </div>
                        <div className='contact-us-social-wrapper'>
                            <FaPhoneAlt /><span className='contact-us-phone'>(647) 632-3421</span>
                            <h3 className='contact-us-social-title'>Connect With Us On Social Media</h3>
                            <a href='https://www.linkedin.com/company/csfp/' target='_blank'><FaLinkedin /></a>
                            <a href='https://www.facebook.com/SalsaInTO' target='_blank'><FaFacebookSquare /></a>
                            <a href='https://twitter.com/salsaintoronto' target='_blank'><FaSquareXTwitter /></a>
                            <a href='https://www.youtube.com/channel/UCzdBvih4GNtYgZKvv7N95vg' target='_blank'><FaSquareYoutube /></a>
                            <a href='https://www.instagram.com/salsaintoronto/' target='_blank'><FaInstagramSquare /></a>
                        </div> 
                    </div>
                </section>
            <Footer />
        </>
    )
}