import '../Styles/jobposttemplate.css'
import React from 'react';
import { useParams } from 'react-router-dom'; // Import useParams to get URL params
import Nav from '../Components/Nav';
import Footer from '../Components/Footer';
import jobPostings from '../Jobs/JobPostings.json';
import { ImInsertTemplate } from 'react-icons/im';

const JobPostTemplate = () => {
  const { route } = useParams(); 

  const renderJobPost = (route) => {
    return jobPostings
      .filter((job) => job.route === route)
      .map((job) => (
        <>
          <div id="application-wrapper-desktop">
            <h1 class="application-title">Job Details</h1>
            <div class="details-wrapper">
              <h1 className='detail-title'>Location</h1>
              <p className='detail-item'>{job.address}</p>
              <h1 className='detail-title'>Position Type</h1>
              <p className='detail-item'>Contract</p>
              <h1 className='detail-title'>Posted</h1>
              <p className='detail-item'>{job.post_date}</p>
            </div>
            <a href={`mailto:work@canadiansalsafestivalsproject.org?subject=${job.job_title}:&amp;`} id="apply-button"><p>Apply Now</p></a>
            <p class="application-instruction">To submit your application, please email your resume and cover letter with the subject line:</p>
            <p class="application-instruction"><strong>{job.job_title}: (Your Name)</strong></p>
          </div>

        <h2 className='mobile-post-job-title'>{job.job_title}</h2>
        <div id="application-mobile-header">
            <h1 class="application-title">Job Details</h1>
            <div class="details-wrapper">
              <h1 className='detail-title'>Location</h1>
              <p className='detail-item'>{job.address}</p>
              <h1 className='detail-title'>Position Type</h1>
              <p className='detail-item'>Contract</p>
              <h1 className='detail-title'>Posted</h1>
              <p className='detail-item'>{job.post_date}</p>
            </div>
        </div>
        <div id="application-mobile-button-wrapper">
        <a href="mailto:hr@tlnmediagroup.com?subject=Media Sales Account Executive Job Application:&amp;" id="apply-button"><p>Apply Now</p></a>
        </div>
        <div id="application-mobile-footer">
        <p class="application-instruction">To submit your application, please email your resume and cover letter with the subject line:</p>
            <p class="application-instruction"><strong>{job.job_title}: (Your Name)</strong></p>
        </div>

        <div className='post-wrapper'>
          <h2 className='post-job-title'>{job.job_title}</h2>
          <h2>Description</h2>
          <div className="post-content-wrapper">
          <p>{job.description}</p>
          <p>{job.term_length}</p>
          </div>
          <h2>Main Responsibilities</h2>
          <div className="post-content-wrapper">
            <ul>
              {job.tasks.map((task) => (
                <li>{task}</li>
              ))}
            </ul>
          </div>
          <h2>Required Skills</h2>
            <div className="post-content-wrapper">
              <ul>
                {job.skills.map((skill) => (
                  <li>{skill}</li>
                ))}
              </ul>
            </div>
          <h2>Qualifications</h2>
          <div className="post-content-wrapper">
                <ul>
                  {job.qualifications.map((item) => (
                    <li>{item}</li>
                  ))}
                </ul>
          </div>
        </div>
        </>
      ));
  };

  return (
    <>
    <Nav/>
    <section className='page-content'>
      <div className='post-template'>
        {renderJobPost(route)}
      </div>
    </section>
    <Footer/>
    </>
  );
};

export default JobPostTemplate;