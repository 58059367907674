import './Styles/communitypartners.css';
import React, {useState, useEffect} from 'react';
import Nav from './Components/Nav.js';
import Footer from './Components/Footer.js';
import tmg from './Media/Logos/TMG_logo.webp';
import tln from './Media/Logos/TLN_Logo.webp';
import univision from './Media/Logos/Univision_Logo.webp';
import hillcrest from './Media/Logos/Logo_Hillcrest_Village.webp';
import lula from './Media/Logos/Logo_lula_music_arts_center.webp';
import toronto from './Media/Logos/city-of-toronto-logo.webp';
import villaChar from './Media/Logos/Villa_Char_RGB_logo.png';
import sorrento from './Media/Logos/Sorrento_Retirement_Residence_Logo.jpg'
import richview from './Media/Logos/Logo_RichviewManor.jpg';
import steps from './Media/Logos/Logo_Steps_Dance_Studio.png';
import soul2sole from './Media/Logos/Logo_sol2sole.jpg';
import salsomanos from './Media/Logos/Logo_los_salsomanos.jpg';
import wychwood from './Media/Logos/Logo_Wychwood_Barns.jpg'

export default function CommunityPartners () {

    return (
        <>
            <Nav />
            <div className='community-partners-page-banner'></div>
            <section className='page-content'>
            <h2 className='desktop-title'>View Community partners below. Hover for more information.</h2>
            <h2 className='mobile-title'>View Community partners below. Click for more information.</h2>
            <row className='hovercard-row'>
                {/* TMG LOGO */}
                <HoverCard 
                    logo={tmg}  
                    title="TLN MEDIA GROUP" 
                    description="TMG, Canada’s top multicultural media company, connects cultures through storytelling across TV, online, and communities. Partnering with CSFP, TMG channels support event initiatives through multimedia marketing, part of the “TLN Cares” national outreach campaign." />
                {/* TLN LOGO */}
                <HoverCard 
                    logo={tln}  
                    title="TLN TV" 
                    description="TLN TV, Canada's diverse English-language channel, unites cultures through shared experiences and vibrant personalities. With adventures abroad, big movies, music specials, and live international soccer, we bring more color into the lives of Canadians. TLN TV, part of the TLN Media Group, is available coast to coast." />
                {/* UNIVISION LOGO */}
                <HoverCard 
                    logo={univision}  
                    title="Univision Canada" 
                    description="Univision, the leading Spanish-language channel in Canada, delivers top-notch programming to 1.8 million Spanish speakers. Owned by TLN Media Group, it reaches nearly 2 million homes across Canada with daily news, award-winning telenovelas, talk shows, live music specials, and soccer coverage." />
            </row>
            <seperator></seperator>
            <row className='hovercard-row'>
                {/* Hillcrest LOGO */}
                <HoverCard 
                    logo={hillcrest}  
                    title="HILLCREST VILLAGE BIA" 
                    description="Hillcrest Village is located in the heart of midtown Toronto, running along St. Clair West between Christie Street and Winona Drive. 
                    This vibrant area is home to a wide variety of retail shops, professional services, specialty restaurants, and cozy ethnic eateries, 
                    all reflecting the diverse ethnicity of the street." />
                {/* Lula LOGO */}
                <HoverCard 
                    logo={lula}  
                    title="LULA ARTS AND MUSIC CENTRE" 
                    description="
                    LMAC, based in Toronto's Lula Lounge, fosters new projects and collaborations through concerts, festivals, workshops, and cultural exchanges, 
                    encouraging musical excellence and cross-cultural pollination with 200 annual concerts." />
                {/* Toronto LOGO */}
                <HoverCard 
                    logo={toronto}  
                    title="CITY COUNCILLOR JOSH MATLOW" 
                    description="Josh Matlow is a community advocate and elected to Toronto City Council on October 25th, 2010, and is now serving his third term as City 
                    Councillor for Toronto-St. Paul’s, Ward 12, the home to the annual TD Salsa on St Clair Street Festival." />
            </row>
            <seperator></seperator>
            <row className='hovercard-row' >
                {/* Villa Charities LOGO */}
                <HoverCard 
                    logo={villaChar}  
                    title="Villa Charities Inc." 
                    description="Villa Charities Inc. (VCI) is a registered charity and non-profit organization that enriches lives through experiences and services that honour Italian culture and heritage. For more than 50 years across the GTA, Villa Charities has provided cultural and educational programs and supported culturally sensitive long-term care and independent living apartments for seniors." />
                {/* Villa Charities LOGO */}
                <HoverCard 
                    logo={sorrento}  
                    title="Sorrento Retirement Residence" 
                    description="Life at Sorrento is about spending time with family, making new connections, and looking forward to the future.
                    It is a place where you can reignite your sense of discovery and enjoy a full and rewarding retirement.
                    " />
                {/* RICHVIEW MANOR LOGO */}
                <HoverCard 
                    logo={richview}  
                    title="Richview Manor Retirement Home" 
                    description="As a premier retirement home, Richview Manor envisions leadership in innovative program development, emphasizing recreational therapies. Serving as a community pillar, we cultivate valuable partnerships, steadfastly advocating for residents to enjoy the highest quality of life they rightfully deserve." />
                <HoverCard 
                    logo={wychwood}
                    title="Wychwood Barns" 
                    description="Wychwood Barns, Barns Art Market (BAM!) is an indoor art market meets art exhibition. This market aims to support local talent within our diverse communities, giving emerging, mid-career and established artists a space to exhibit and sell their work. Wychwood Barns is operated by the social enterprise ArtsHub." />
            </row>
            <seperator></seperator>
            <row className='hovercard-row'>
                {/* Steps LOGO */}
                <HoverCard 
                    logo={steps}  
                    title="Steps Dance Studio" 
                    description="Working from a comprehensive dance syllabus designed by Jennifer Aucoin and refined by more than 20 years of teaching experience, 
                            Step’s professionally trained instructors are patient, dedicated and passionate about their craft. Steps offers a complete curriculum 
                            of Salsa, Bachata, Hustle and Latin Dance for all skill levels." />
                {/* Latin Dance Co LOGO */}
                <HoverCard 
                    logo={soul2sole}  
                    title="Soul2Sole Dance Company" 
                    description="A dynamic group of professional dancers with the mission to share their passion for Latin Dance. With more than 10 years of teaching experience 
                    and success in instructing their students, Soul2Sole continues their journey inspiring students beyond the dance floor." />
                {/* Los Salsomanos LOGO */}
               <HoverCard 
                    logo={salsomanos}  
                    title="Los Salsomanos" 
                    description={"Giovanni Torres (DJ Gio) is a multifaceted dance professional, excelling as a performer, instructor, choreographer, and judge. He founded the renowned dance group in Toronto, \"Los Salsomanos,\" a dynamic and passionate Latin Dance Company known for their energetic style, elegance, and flavorful approach to salsa."} />
            </row>
            </section>
            <Footer />
        </>
    );
};

function HoverCard (params) {
    const [windowSize, setWindowSize] = useState({
        width: window.innerWidth,
        height: window.innerHeight,
    });

    const updateWindowSize = () => {
        setWindowSize({
            width: window.innerWidth,
            height: window.innerHeight,
        });
    };

    useEffect(() => {
        // Add event listener when component mounts
        window.addEventListener('resize', updateWindowSize);
    
        // Remove event listener when component unmounts
        return () => {
          window.removeEventListener('resize', updateWindowSize);
        };
      }, []); // Empty dependency array means this effect runs once when the component mounts
    

    const [isHoverCardOpen, setIsHoverCardOpen] = useState(false);

    const openHoverCard = () => {
        if(windowSize.width <= 768) {
            setIsHoverCardOpen(true);
        }
    }

    return(
        <>
        <div className='hovercard' onClick={openHoverCard} >
            <div className='hovercard-front'>
                <img src={ params.logo } />
            </div>
            <div className='hovercard-back'>
                <h1 className='info-title'>{ params.title }</h1>
                <p className='info-description'>
                    { params.description }
                </p>
            </div>
        </div>

        {isHoverCardOpen && (
            <div className='hovercard-overlay' onClick={() => setIsHoverCardOpen(false)}>
                <div className='hovercard-content'>
                    <span className='close-hovercard' onClick={() => setIsHoverCardOpen(false)}>
                    &times;
                    </span>
                    <div className='hovercard-overlay-info'>
                        <h1 className='info-title'>{ params.title }</h1>
                        <p className='info-description'>
                            { params.description }
                        </p>
                    </div>
                </div>
            </div>
        )}
        </>
    )
}