import Nav from './Components/Nav';
import Footer from './Components/Footer';
import Gallery from './Components/GridGallery';
import Img1 from './Media/Galleries/HomepageGallery/IMG_1.webp';
import Img2 from './Media/Galleries/HomepageGallery/IMG_2.webp';
import Img3 from './Media/Galleries/HomepageGallery/IMG_3.webp';
import Img4 from './Media/Galleries/HomepageGallery/IMG_4.webp';
import Img5 from './Media/Galleries/HomepageGallery/IMG_5.webp';
import Img6 from './Media/Galleries/HomepageGallery/IMG_6.webp';
import Img7 from './Media/Galleries/HomepageGallery/IMG_7.webp';
import Img8 from './Media/Galleries/HomepageGallery/IMG_8.webp';
import Img9 from './Media/Galleries/HomepageGallery/IMG_9.webp';
import Img10 from './Media/Galleries/HomepageGallery/IMG_10.webp';
import Img11 from './Media/Galleries/HomepageGallery/IMG_11.webp';
import Img12 from './Media/Galleries/HomepageGallery/IMG_12.webp';

    const gallery = [
        {
            "index": 0,
            "id":"img1",
            "filename":Img1,
        },
        {
            "index": 1,
            "id":"img2",
            "filename":Img2
        },
        {
            "index": 2,
            "id":"img3",
            "filename":Img3
        },
        {
            "index": 3,
            "id":"img4",
            "filename":Img4
        },
        {
            "index": 4,
            "id":"img5",
            "filename":Img5
        },
        {
            "index": 5,
            "id":"img6",
            "filename":Img6
        },
        {
            "index": 6,
            "id":"img7",
            "filename":Img7
        },
        {
            "index": 7,
            "id":"img8",
            "filename":Img8
        },
        {
            "index": 8,
            "id":"img9",
            "filename":Img9  
        },
        {
            "index": 9,
            "id":"img10",
            "filename":Img10       
        },
        {
            "index": 10,
            "id":"img11",
            "filename":Img11,
        },
        {
            "index": 11,
            "id":"img12",
            "filename":Img12
        }
    ];

export default function Homepage() {
    return (
        <>
        <Nav />
        <div className='page-content' id="homepage-content">
            <div className='gallery-wrapper'>
                <div className='gallery-content-wrapper'>
                    <h1>Canadian Salsa<br></br>Festivals Project</h1>
                    <p> Our non-profit creates, supports, and promotes ethno-culturally relevant heritage works, fostering appreciation and participation in our diverse traditions.</p>
                    <a className='call-to-action-link' href="/get-involved"><span>Get Involved</span></a>
                </div>
                <Gallery galleryJson={gallery}/>
            </div>
        </div>
        <Footer />
        </>
   )
}