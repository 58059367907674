import './Styles/salsaforseniors.css';
import Nav from './Components/Nav';
import Footer from './Components/Footer';
import villaCharities from './Media/Logos/Card_Villa_charities_1000x500.png'
import sorrento from './Media/Logos/Card_Sorrento_1000x500.png'
import wychwood from './Media/Logos/Card_Wychwood_Barns_1000x500.png'
import steps from './Media/Logos/Card_Steps_Dance_Studio.png'
import ritmo from './Media/Logos/Card_Ritmo_Caliente_Entertainment.png'
import inspiracion from './Media/Logos/Card_InspiracionLatina-School.png'
import soul from './Media/Logos/Card_Soul_to_Sole.png'
import { IoIosTime } from "react-icons/io";
import { FaLocationDot } from "react-icons/fa6";

export default function SalsaForSeniors() {

    return (
        <>
        <Nav />
        <div className='sfs-page-banner'></div>
        <section className='page-content'>
            <h1 className='title'>SALSA FOR SENIORS PROGRAM</h1>
            <p>Since 2021, we’ve introduced a new program called ‘Salsa for Seniors’ to fully include seniors in our community as part of the annual TD Salsa in Toronto Festival activities. Dance your way through FREE Salsa and Latin dance lessons presented by TD Salsa in Toronto Festival, with the best professionals in Toronto and around the GTA! These lessons are for seniors of all ages, and there is no experience needed! No registration is required.
            <strong> CHECK OUT OUR 2024 LESSONS BELOW.</strong>
            </p>
            <h1 className='sub-title'>Salsa for Seniors at Sorrento</h1>
            <div className='event-card'>
                <div class="date-wrapper">
                    <h2 className='sub-title'>JUNE</h2>
                    <h2 className="sub-title">14</h2>
                </div>
                <div className='event-details'>
                    <div className='seniors-event-info'>
                        <div className='event-time'>
                        <IoIosTime />
                        <p>3:30-5:30 PM/ET</p>
                        </div>
                        <div className='event-address'>
                        <FaLocationDot />
                        <p>10 Station Rd, Bolton, ON L7E 4L3, Canada</p>
                        </div>
                    </div>
                </div>
                <a className='event-rsvp-btn' href="https://www.universe.com/events/salsa-for-seniors-in-bolton-td-salsa-in-toronto-tickets-3FGZJB" target="blank_">RSVP</a>
            </div>
            <h1 className='sub-title'>Salsa for Seniors at Columbus Centre</h1>
            <div className='event-card'>
                <div class="date-wrapper">
                    <h2 className='sub-title'>JULY</h2>
                    <h2 className="sub-title">3 & 10</h2>
                </div>
                <div className='event-details'>
                    <div className='seniors-event-info'>
                        <div className='event-time'>
                        <IoIosTime />
                        <p>5:15-6:15 PM/ET</p>
                        </div>
                        <div className='event-address'>
                        <FaLocationDot />
                        <p>Columbus Centre | 901 Lawrence Ave W, Toronto, ON</p>
                        </div>
                    </div>
                </div>
                <a className='event-rsvp-btn' href="https://www.universe.com/events/salsa-for-seniors-in-north-york-td-salsa-in-toronto-tickets-9LYJTS" target="blank_">RSVP</a>
            </div>
            <h1 className='sub-title'>Salsa for Seniors at TD Salsa on St Clair</h1>
            <div className='event-card'>
                <div class="date-wrapper">
                    <h2 className='sub-title'>JULY</h2>
                    <h2 className="sub-title">6</h2>
                </div>
                <div className='event-details'>
                    <div className='seniors-event-info'>
                        <div className='event-time'>
                        <IoIosTime />
                        <p>2:00-3:00 PM/ET</p>
                        </div>
                        <div className='event-address'>
                        <FaLocationDot />
                        <p>76 Wychwood Ave, Toronto, ON M6G 2X7, Canada</p>
                        </div>
                    </div>
                </div>
                <a className='event-rsvp-btn' href="https://www.universe.com/events/salsa-for-seniors-in-toronto-td-salsa-in-toronto-tickets-9NGYBZ" target="blank_">RSVP</a>
            </div>
            <h2>Participating Locations</h2>
            <div className='location-wrapper'>
                <div className='location'>
                    <a href="https://www.villacharities.com/" target="blank_"><img src={villaCharities} alt="villa charities logo"></img></a>
                    <p>Villa Charities Inc. (VCI) is a registered charity and non-profit organization that enriches lives through experiences and services that honour Italian culture and heritage. For more than 50 years across the GTA, Villa Charities has provided cultural and educational programs and supported culturally sensitive long-term care and independent living apartments for seniors.</p>
                </div>
                <div className='location'>
                    <a href="https://sorrentoretirement.com/" target="blank_"><img src={sorrento} alt="sorrento retirement residence logo"></img></a>
                    <p>Life at Sorrento is about spending time with family, making new connections, and looking forward to the future. Sorrento is more than a retirement home; it is a place where you can reignite your sense of discovery and enjoy a full and rewarding retirement. Choose to live in one of our stunning suites and you’ll be treated to a variety of amenities, unparalleled services, and exquisite dining.</p>
                </div>
                <div className='location'>
                    <a href="https://wychwoodbarns.ca/" target="blank_"><img src={wychwood} alt="wychwood barns logo"></img></a>
                    <p>Wychwood Barns, Barns Art Market (BAM!) is an indoor art market meets art exhibition. This market aims to support local talent within our diverse communities, giving emerging, mid-career and established artists a space to exhibit and sell their work. Wychwood Barns is operated by the social enterprise ArtsHub.</p>
                </div>
            </div>
        </section>
        <section id="school-section">
            <h1 className='title'>Participating Schools</h1>
                <div className='school-wrapper'>
                    <div className='school'>
                        <a className="school-link" href="https://www.stepsdancestudio.com/" target="blank_"><img src={steps} alt="Steps Dance Studio Inc."></img></a>
                        <p>Steps offers a comprehensive curriculum of Salsa, Bachata, Hustle, and Latin Dance at all skill levels, guided by a syllabus refined over 20 years by Jennifer Aucoin, with instructors who are professionally trained, patient, dedicated, and passionate about their craft.</p>
                    </div>
                    <div className='school'>
                        <a className="school-link" href="https://sorrentoretirement.com/" target="blank_"><img src={ritmo} alt="Ritmo Caliente Entertainment"></img></a>
                        <p>With over 30 years of experience and a repertoire spanning salsa, merengue, bachata, cumbia, hip hop, samba, belly dance, jazz, and more, Ritmo Caliente is a versatile and interactive dance team dedicated to delivering outstanding entertainment at various events.</p>
                    </div>
                    <div className='school'>
                        <a className="school-link" href="https://salsaintoronto.com/speaker/ritmo-caliente/" target="blank_"><img src={inspiracion} alt="Inspiracion Latina"></img></a>
                        <p>Inspiracion Latina Dance, founded by Soraya Lozano in 2013, provides live entertainment featuring a diverse range of dance styles including Latin, Peruvian, Bellydance, Hawaiian, and Las Vegas Show performances for various events.</p>
                    </div>
                    <div className='school'>
                        <a className="school-link" href="https://soul2sole.ca/" target="blank_"><img src={soul} alt="Soul 2 Sole Latin Dance CO."></img></a>
                        <p>Soul2Sole is a dynamic group of professional dancers with the mission to share their passion for Latin Dance. With more than 10 years of teaching experience and success in instructing their students, Soul2Sole continues their journey inspiring students beyond the dance floor.</p>
                    </div>
                </div>
        </section>
        <Footer />
        </>
    );
}